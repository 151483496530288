import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import "swiper/css";

function NumberedCards({ numberedCardsContent, cssModifer }) {
  const cardContent = (title, text, buttonText, buttonUrl, key, icon) => {
    if (buttonUrl) {
      return (
        <Link to={buttonUrl}>
          <div
            className={`m-numberedCards__card ${
              cssModifer ? cssModifer : "javascript:void(0);"
            }`}
          >
            <div className="m-numberedCards__card--header" key={key}>
              {/* {icon} */}
              <span className="m-numberedCards__card--number">
                <span className="m-numberedCards__card--numberNum">
                  0{key + 1}
                </span>{" "}
              </span>
              <h3 className="m-numberedCards__card--title">{title}</h3>
            </div>

            <p className="m-numberedCards__card--text">{text}</p>
            <div className="m-cardLinks">
              <button className="a-btn -tertiary">{buttonText}</button>
              <span className="a-arrow -long"></span>
            </div>
          </div>
        </Link>
      );
    } else {
      return (
        <div className="-card">
          <div
            className={`m-numberedCards__card ${
              cssModifer ? cssModifer : "javascript:void(0);"
            }`}
          >
            <div className="m-numberedCards__card--header" key={key}>
              {/* {icon} */}
              <span className="m-numberedCards__card--number">
                <span className="m-numberedCards__card--numberNum">
                  0{key + 1}
                </span>{" "}
              </span>
              <h3 className="m-numberedCards__card--title">{title}</h3>
            </div>

            <p className="m-numberedCards__card--text">{text}</p>
          </div>
        </div>
      );
    }
  };
  return (
    <section className={`m-numberedCards ${cssModifer ? cssModifer : ""}`}>
      <div className="_wr">
        <div className="_w m-numberedCards__contentTop">
          <div className="_l8">
            <h2 className="m-numberedCards__contentTop--title">
              {numberedCardsContent.title}
            </h2>
            <span className="a-line -red"></span>
            {numberedCardsContent.text && (
              <p className="m-numberedCards__contentTop--text">
                {numberedCardsContent.text}
              </p>
            )}
          </div>
        </div>
        <div className="m-numberedCards__contentBottom">
          {/* {breakpoints.md ? (
            <Swiper
              spaceBetween={20}
              slidesPerView={1.4}
              breakpoints={breakpointsObj}
            >
              {numberedCardsContent.cards.map(
                ({ title, text, buttonText, buttonUrl, icon }, key) => (
                  <SwiperSlide key={key}>
                    {cardContent(title, text, buttonText, buttonUrl, key, icon)}
                  </SwiperSlide>
                )
              )}
            </Swiper>
          ) : ( */}
          <div className="_w m-numberedCards__cardsContainer">
            {numberedCardsContent.cards.map(
              ({ title, text, buttonText, buttonUrl, icon }, key) => (
                <div
                  className="m-numberedCards__cardsContainer--card _m6 _xl3"
                  key={key}
                >
                  {cardContent(title, text, buttonText, buttonUrl, key, icon)}
                </div>
              )
            )}
          </div>
          {/* )} */}
        </div>
      </div>
    </section>
  );
}

export default NumberedCards;
