import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import HeaderHome from "../components/headers/header-home";
import NumberedCards from "../components/homepage/numbered-cards";
import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo";
import Text from "../components/global/text";
import Projects from "../components/global/projects";
import Icons from "../components/homepage/icons";
import Develop from "../components/global/develop";
import Language from "../components/homepage/language";
import Markets from "../components/homepage/markets";
import { graphql } from "gatsby";

import GroupIcon from "../assets/images/homepage/team.svg";
import SetupIcon from "../assets/images/homepage/setUp.svg";
import FocusIcon from "../assets/images/homepage/focus2.svg";
import ReinventedIcon from "../assets/images/homepage/development.svg";

import First from "../assets/images/homepage/first.svg";
import Third from "../assets/images/homepage/third.svg";
import Fourth from "../assets/images/homepage/fourth.svg";
const IndexPage = () => {
  const { t } = useTranslation();

  const numberedCardsContent = {
    title: `${t("home.numberedCards.headline")}`,
    text: `${t("home.numberedCards.text")}`,
    cards: [
      {
        title: `${t("home.numberedCards.cards.appDev.headline")}`,
        text: `${t("home.numberedCards.cards.appDev.text")}`,
        buttonText: `${t("home.numberedCards.cards.appDev.button")}`,
        buttonUrl: `/it-services/application-development`,
        icon: <First />,
      },
      {
        title: `${t("home.numberedCards.cards.engTeam.headline")}`,
        text: `${t("home.numberedCards.cards.engTeam.text")}`,
        buttonText: `${t("home.numberedCards.cards.engTeam.button")}`,
        buttonUrl: `/it-services/engineering-team`,
        icon: <First />,
      },
      {
        title: `${t("home.numberedCards.cards.reEng.headline")}`,
        text: `${t("home.numberedCards.cards.reEng.text")}`,
        buttonText: `${t("home.numberedCards.cards.reEng.button")}`,
        buttonUrl: `/it-services/application-re-engineering`,
        icon: <Third />,
      },
      {
        title: `${t("home.numberedCards.cards.otherServices.headline")}`,
        text: `${t("home.numberedCards.cards.otherServices.text")}`,
        buttonText: `${t("home.numberedCards.cards.otherServices.button")}`,
        buttonUrl: `/it-services/other-services`,
        icon: <Fourth />,
      },
    ],
  };

  const iconsData = [
    {
      icon: <FocusIcon />,
      title: `${t("home.icons.cards.first.headline")}`,
      text: `${t("home.icons.cards.first.text")}`,
    },
    {
      icon: <ReinventedIcon />,
      title: `${t("home.icons.cards.second.headline")}`,
      text: `${t("home.icons.cards.second.text")}`,
    },
    {
      icon: <GroupIcon />,
      title: `${t("home.icons.cards.third.headline")}`,
      text: `${t("home.icons.cards.third.text")}`,
    },
    {
      icon: <SetupIcon />,
      title: `${t("home.icons.cards.fourth.headline")}`,
      text: `${t("home.icons.cards.fourth.text")}`,
    },
  ];

  // Text Component
  const textContent = {
    title: `${t("home.rightPartner.headline")}`,
    text: `${t("home.rightPartner.text")}`,
  };

  const textContent2 = {
    title: `${t("home.icons.headline")}`,
    text: `${t("home.icons.text")}`,
  };
  return (
    <Layout>
      <Seo title="Enterprise Web Applications" />
      <HeaderHome />
      {/* <BgDivider /> */}
      <Markets />
      <NumberedCards
        numberedCardsContent={numberedCardsContent}
        cssModifer="-linkCards"
      />
      <Text textContent={textContent} cssModifer="-homeText" />
      <Projects cssModifer="-projectsHome" />
      <Text textContent={textContent2} cssModifer="-white -icons -homeText" />
      <Icons iconsData={iconsData} />
      <Language />
      <Develop />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
