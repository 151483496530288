import React from "react";
import Typed from "react-typed";
import { Link } from "gatsby-plugin-react-i18next";
import { useTranslation } from "react-i18next";
import BgImage from "../../assets/images/headers/BgImage.png";

const HeaderHome = () => {
  const { t } = useTranslation();
  return (
    <header className="m-headerHome">
      {/* <div className="_wr m-headerHome__wrapper" style={{ backgroundImage: `url("${headerContent.imageUrl}")` }}> */}
      <div className="_wr m-headerHome__wrapper">
        {/* <div className="m-cubesBg">
          <CubesMobile />
          <Cubes />
        </div> */}
        <div className="_w m-headerHome__content">
          <div className=" _l6 m-headerHome__textContent">
            <h1 className="m-headerHome__textContent--title">
              {t("home.typewriter.staticText")}{" "}
              <Typed
                strings={[
                  `${t("home.typewriter.changingText.first")}`,
                  `${t("home.typewriter.changingText.second")}`,
                  `${t("home.typewriter.changingText.third")}`,
                  `${t("home.typewriter.changingText.fourth")}`,
                  `${t("home.typewriter.changingText.fifth")}`,
                ]}
                typeSpeed={70}
                backSpeed={60}
                backDelay={1200}
                loop
              />
            </h1>
            <h2 className="m-headerHome__textContent--subtitle">
              {t("home.subTitle")}
            </h2>

            <p className="m-headerHome__text">{t("home.text")}</p>

            {/* <ul className="m-headerHome__list">
              {headerContent.list.map((item, i) => (
                <li className="" key={i} >
                  {item}
                </li>
              ))}
            </ul> */}
            <div className="m-headerHome__buttons">
              <Link to="/our-work" className="a-btn -primary">
                {t("buttons.seeOurWork")}
              </Link>
              {/* <button href="/" className="a-btn -borderBlack a-hover -effectThree">Estimate your app costs</button> */}
            </div>
          </div>
          <div
            className="m-headerMarkets__bkg -home _m5 _xl6"
            style={{ backgroundImage: `url("${BgImage}")` }}
          ></div>
        </div>
      </div>
    </header>
  );
};

export default HeaderHome;
